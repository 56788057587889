.pagination {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    margin-top: 1rem;
}

.pagination button {
    width: 40px;
    height: 40px;
    font-family: inherit;
    font-weight: 600;
    font-size: 16px;
    margin: 0 10px;
    border-radius: 50%;
    cursor: pointer;
    transition: all 0.3s ease;
    background: transparent;
    color: black;
    border: 0.5px solid rgb(138, 138, 138);
}

.pagination button.active {
    font-weight: 900;
    border: 0.5px solid rgb(138, 138, 138);
    background: #5EBDCC;
    color: #101010;
}