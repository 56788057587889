@font-face {
  font-family: "Roobert";
  src: url("./Font/RoobertMedium.ttf") format("truetype");
  font-weight: 500; /* Medium weight is typically 500 */
  font-style: normal; /* Use normal for non-italic text */
}
body {
  margin: 0;
  overflow-x: hidden;
  font-family: "Roobert";
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: #fcfcfc;
  font-size: 1.1rem;
  /* background-color: white !important; */
}

/* .MuiToolbar-root.MuiToolbar-regular {
  min-height: 64px;
} */
code,
table,
tr,
td {
  font-family: "Roobert" !important;
}

.expandTable {
  padding: 30px;
  background: #f4f4f4;
}

.expandTable .MuiToolbar-regular {
  min-height: auto !important;
}

.expandTable thead tr th {
  background: #75cbff;
  font-weight: bold;
}

.expandTable tbody tr td {
  font-size: 13px !important;
}

.tableOverFlowHidden > div > div > div:nth-child(2) {
  max-height: 500px !important;
  min-height: 500px !important;
}

.tableOverFlowHidden > div > div > div:nth-child(2) > div:nth-child(1) {
  display: none;
}

/* jira tab (expand jira dyanamic table) : time line */
.items-wrap {
  /* display: flex;
  align-items: center;
  justify-content: center;
  border: 1px solid #ccc; */
  min-width: max-content;
  /* gap: 20px;
  padding: 0px 10px; */
}

.items-wrap-two {
  padding: 10px;
  max-width: calc(100vw - 160px);
  overflow: auto;
}

.item {
  padding-top: 10px;
  padding-bottom: 15px;
}

.item:before {
  content: "";
  text-align: center;
  width: 10px;
  height: 10px;
  display: block;
  border: 2px solid var(--color);
  background: var(--color);
  border-radius: 50%;
  margin: -15px 0 0;
}

.item span {
  display: block;
  text-align: center;
  font-size: 12px;
}

.item span.date {
  margin-top: 2px;
  margin-bottom: 10px;
  color: #585ce5;
}

.item span.facility-name {
  margin-bottom: 2px;
  color: #666;
}

.item span.doctor-name {
  color: #000;
}

/* time line css */
/* * {
  padding: 0%;
  margin: 0%;
  box-sizing: border-box;
}
body {
  background-color: #bdbdbd47;
  padding: 50px 100px;
} */
.Arrow-main {
  margin-right: 6px;
}

.arrow-top-line {
  height: 8px;
  background-color: #626cbf;
  transform: skew(25deg);
}

.contain {
  /* padding: 12px 16px; old */
  padding: 9px 10px;
  position: relative;
}

.contain::before {
  content: " ";
  position: absolute;
  width: 4px;
  height: 50%;
  right: -21px;
  top: 61%;
  background-color: lightgray;
  transform: skew(-25deg);
}

.contain::after {
  content: " ";
  position: absolute;
  width: 4px;
  top: 10px;
  right: -21px;
  height: 50%;
  background-color: lightgray;
  transform: skew(25deg);
}

.contain h3 {
  font-size: 20px;
  padding: 0px 20px;
  color: #626cbf96;
  margin: 0px;
}

.contain label {
  font-size: 16px;
  padding: 0px 20px;
  color: #626cbf;
  font-weight: 800;
}

.contain b {
  color: #626cbf96;
}

.arrow-container {
  display: flex;
}

.arrow-container .Arrow-main:last-child .contain::after {
  display: none;
}

.arrow-container .Arrow-main:last-child .contain::before {
  display: none;
}

.cursorPointer {
  cursor: pointer;
}

.resourceHealthLabel {
  font-size: 16px;
  color: #898585;
}

::-webkit-scrollbar {
  width: 10px;
  height: 10px;
}

/* Track styles */
::-webkit-scrollbar-track {
  background: #a2a3a3;
  border-radius: 50px;
}

/* Handle styles */
::-webkit-scrollbar-thumb {
  background: #5ebdcc;
  border-radius: 50px;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: #5ebdcc;
  border-radius: 50px;
}

.MuiTab-wrapper {
  display: flex;
}

iframe {
  z-index: -1 !important;
}

.resourceStyling div {
  box-shadow: none !important;
}

th > div {
  font: normal normal normal 14px/18px Lexend Deca;
}

/* pagination styling */
.paginationStyling .MuiTablePagination-toolbar {
  position: relative;
}

.paginationStyling
  .MuiTablePagination-toolbar
  .MuiButtonBase-root.MuiButton-root.MuiButton-contained.MuiButton-containedSizeSmall.MuiButton-sizeSmall.Mui-disabled.Mui-disabled {
  background: #5ebdcc;
  color: #fff;
  font: normal normal normal 12px/15px Lexend Deca;
  border-radius: 20px;
}

.paginationStyling
  .MuiTablePagination-toolbar
  .MuiTablePagination-selectRoot.MuiTablePagination-input {
  position: absolute;
}

.paginationStyling
  .MuiTablePagination-toolbar
  .MuiTablePagination-selectRoot
  > div {
  border: 1px solid #707070;
  padding: 7px 15px 7px 5px;
  margin-left: 10px;
  border-radius: 16px;
}

.paginationStyling .MuiTablePagination-toolbar .MuiTablePagination-selectRoot {
  margin-left: 10px;
}

.paginationStyling
  .MuiTablePagination-toolbar
  .MuiTablePagination-selectRoot::before {
  content: "show";
  font: normal normal normal 12px/15px Lexend Deca;
  letter-spacing: 0px;
  color: #707070;
  opacity: 1;
}

.paginationStyling
  .MuiTablePagination-toolbar
  .MuiTablePagination-selectRoot::after {
  content: "per page";
  position: absolute;
  right: -70px;
  font: normal normal normal 12px/15px Lexend Deca;
  letter-spacing: 0px;
  color: #707070;
  opacity: 1;
}

.paginationStyling .MTablePaginationInner-root-118 > span:first-child,
.paginationStyling .MTablePaginationInner-root-118 > span:last-child {
  display: none;
}

.paginationStyling .MTablePaginationInner-root-118 > span:nth-child(2) button,
.paginationStyling
  .MTablePaginationInner-root-118
  > span:nth-last-child(2)
  button {
  border: 1px solid #b1b1b1;
  font: normal normal normal 12px/15px Lexend Deca;
  border-radius: 50%;
  padding: 3px;
}

.paginationStyling .MTablePaginationInner-root-118 span button {
  margin: 0 10px;
}

.customCard {
  box-shadow: 0px 6px 6px #00000003 !important;
  border-radius: 14px !important;
}

.teamMainContainer {
  margin-top: -62px;
}

/* to remove table search underline */
.MuiInput-underline:before,
.MuiInput-underline:after {
  border: 0px !important;
}

/* dropdown CSS */
.MuiSelect-outlined.MuiSelect-outlined {
  font-size: 14px !important;
}

/* error message code */
.errorMsg {
  color: #ff0000;
  font: normal normal normal 14px/14px Lexend Deca;
  margin-top: 5px;
}

/* loader CSS*/
.loader {
  display: none;
  position: fixed;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  z-index: 10000;
}

.loading-indicator .loader {
  display: flex;
  justify-content: center;
  align-items: center;
  background: #e4e0e0cc;
}

/* nesting tabs */
.nestingTabs button.MuiTab-root {
  background: #f7f7f7;
  box-shadow: none !important;
  border: 0px !important;
  border-radius: 30px !important;
  color: #b1b1b1 !important;
}

.nestingTabs button[aria-selected="true"] {
  background: #eff8fa;
  color: #5ebdcc !important;
}

.nestingTabs .MuiTabs-scroller > span {
  display: none;
}

.nestingTabs > div {
  border-bottom: 0px;
}

/* setting form css */
.cancelBtnStyle {
  background: transparent;
  border: 1px solid #707070;
  cursor: pointer;
  font: normal normal normal 16px/20px Lexend Deca;
  border-radius: 22px;
  width: 120px;
  padding: 8px 15px;
  margin: 15px 15px 15px 0;
}

.applybtnStyle {
  background: #5ebdcc;
  color: #ffff;
  cursor: pointer;
  font: normal normal normal 16px/20px Lexend Deca;
  border-radius: 22px;
  width: 120px;
  padding: 8px 8px;
  margin: 15px 5px;
  border: 2px solid #5ebdcc;
}

.applybtnStyle:hover {
  background: #26a6bb;
  border: 2px solid #26a6bb;
}

.riskText {
  font: normal normal normal 17px/18px Lexend Deca;
  color: #b1b1b1;
  margin-bottom: 20px;
  line-height: 1.5rem;
}

.minHeight {
  min-height: calc(100vh - 200px);
}

.MuiCardContent-root:last-child {
  /* padding-bottom: 0px !important; */
}

.MuiFormHelperText-marginDense {
  margin-top: 0px !important;
}

.MuiTab-textColorPrimary.Mui-selected {
  color: #26a6bb !important;
}

.MuiTabs-indicator {
  background-color: #26a6bb !important;
}

/* legend code */
/* Style for the legend container */
.legend {
  display: flex;
  flex-wrap: wrap;
  gap: 10px;
  font-size: 16px;
  margin-top: 10px;
  justify-content: center;
}

/* Style for each legend item */
.legend-item {
  display: flex;
  /* align-items: center; */
  text-align: left;
}

/* Style for the legend color box */
.legend-color {
  width: 20px;
  height: 20px;
  margin-right: 5px;
  border: 1px solid #000;
  display: inline-block;
}

/* horizontal time line styling */
.horizontalTimeLine ul {
  flex-direction: row;
}

.horizontalTimeLine ul li {
  flex: 1;
  flex-direction: column;
}

.horizontalTimeLine ul li .MuiTimelineSeparator-root {
  flex-direction: row;
}

.horizontalTimeLine ul li .MuiTimelineConnector-root {
  height: 1px;
}

/* horizontal progress */
.progress-container {
  display: flex;
  height: 10px;
  border-radius: 10px;
  overflow: hidden;
  margin-top: 10px;
}

.progress-bar {
  height: 100%;
}

.text-left {
  text-align: left !important;
}

.font-weight-light {
  font-weight: 300;
}

.text-light {
  color: #97a8b9 !important;
}

/* CSS */
.rdrDefinedRangesWrapper {
  display: none !important;
}

th.MuiTableCell-root.MuiTableCell-head {
  border-left: 1px solid #fff;
}

.viewMore {
  cursor: pointer;
  color: #26a6bb;
}

.metricStatus {
  position: absolute;
  top: 2px;
  right: 2px;
}

/* .metricChips {
  min-height: 20px;
  min-width: 20px;
} */

.investmentProfileDates svg {
  border: 2px solid rgb(100, 132, 247);
  border-radius: 50%;
  width: 28px;
  height: 28px;
}
.midLine {
  width: 100%;
  height: 2px;
  background-color: lightgray;
  margin: 0px 5px;
  border-radius: 2px;
}

.OnTime,
.Open,
.Done {
  background-color: #00800042;
  padding: 3px;
  text-align: center;
  border-radius: 12px;
  color: green;
  font-weight: 500;
}
.Delays {
  background-color: #ff00004d;
  padding: 3px;
  text-align: center;
  border-radius: 12px;
  color: red;
  font-weight: 500;
}
.yellow {
  background-color: #fff374;
  padding: 3px;
  text-align: center;
  border-radius: 12px;
  color: #a38217;
  font-weight: 500;
}

.MuiLinearProgress-bar {
  background-color: #41ea83 !important;
  background-image: linear-gradient(166deg, #55d1b5, transparent);
}

.btnGroup button {
  text-transform: capitalize;
}

.popup {
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  background-color: #ffffff;
  position: absolute;
  border: 1px double lightgray;
  padding: 20px;
  border-radius: 6px;
  width: 820px;
  box-shadow: rgba(0, 0, 0, 0.25) 0px 54px 55px,
    rgba(0, 0, 0, 0.12) 0px -12px 30px, rgba(0, 0, 0, 0.12) 0px 4px 6px,
    rgba(0, 0, 0, 0.17) 0px 12px 13px, rgba(0, 0, 0, 0.09) 0px -3px 5px;
}

.riskButtons {
  z-index: 1;
  right: 305px;
  top: 15px;
}
.riskButtons > button {
  border: none;
  background-color: transparent;
}

.grayColor {
  background-color: gray !important;
  padding: 3px;
  text-align: center;
  border-radius: 12px;
  color: white;
  font-weight: 500;
}
