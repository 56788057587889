.healthTable table tbody tr td {
  font-weight: 600;
  color: #0000;
}
.sonar-wrapper {
  position: relative;
  z-index: 0;
  margin-left: 20px;
  cursor: pointer;
}

/* The circle */
.sonar-emitter {
  position: relative;
  margin: 0 auto;
  width: 20px;
  height: 20px;
  border-radius: 9999px;
  background-color: black;
}

/* the 'wave', same shape and size as its parent */
.sonar-wave {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  border-radius: 9999px;
  background-color: gray;
  opacity: 0;
  z-index: -1;
}

.sonar-wave {
  animation: sonarWave 2s linear infinite;
}

@keyframes sonarWave {
  from {
    opacity: 0.4;
  }
  to {
    transform: scale(3);
    opacity: 0;
  }
}

.delayOnTime {
  background-color: #ffefec;
  border: 1px solid #d9a5a3;
  color: #c56474;
  padding: 2px 6px;
  border-radius: 2px;
}

.delayOnTimeAfter {
  background-color: #dcffe9bf;
  border: 1px solid #bef0db;
  color: #79d9b1;
  padding: 2px 6px;
  border-radius: 2px;
}

.accelerateDay {
  background-color: #e6f7ff;
  border: 1px solid #b5d0da;
  margin: 18px 4px;
  padding: 6px 12px;
  border-radius: 4px;
}
