.label {
  font: normal normal 600 16px/20px Lexend Deca;
  letter-spacing: 0px;
  color: #1e1e1e;
  margin-bottom: 10px;
}

.checkLabel {
  font-size: 14px;
  margin-bottom: 0;
}

.updateBtn {
  background: #5ebdcc;
  color: #ffffff;
  font: normal normal 600 16px/22px Lexend Deca;
  padding: 10px 36px;
  border-radius: 30px;
  border: 0px;
  margin-top: 20px;
}